<template>
  <div>
    <br v-if="isMobile">
    <div class="container-fluid">   
       <b-row class="justify-content-center">
         <b-col cols="11" md="5">   
            <p class="text-center text-white" style="font-size:24px;"><strong>¡Haz Gato!</strong></p>          
            <p class="text-center text-white" style="font-size:18px;">Eres el jugador con la <strong>"X"</strong> forma tu línea horizontal, vertical o diagonal.
            </p>
            <!-- <div class="text-center" id="countdown2" v-if="!isMobile">
                <h2 style="cursor:pointer; font-size:40px; font-family:Saira Stencil One, cursive;"  id="countdown2">
                    <img src="elementos/reloj.png" class="rounded" alt="Image" :style="!isMobile ? 'width:4%; margin-top:-15px;' : ''"> {{ countdown }}
                </h2>
            </div> -->
            <div  id="fondoPista" > 
              <b-card  style="background-color:rgba(0, 0, 0, 0); border-color:#187abe;" class="mt-5">
               <b-card-text class="text-center">
                <br>
                 <p class="text-center" style="font-size:18px; color:#5ab71a; text-shadow: black 0.8em 0.1em 0.2em;" v-if="correcto == 0">
                    Para comenzar da clic en cualquier recuadro azul. ¡Suerte!
                 </p>
                <!-- <img src="elementos/eme.png" class="rounded" alt="Image" :style="!isMobile ? 'width:28%;' : 'width:15%;'"> -->
                <br>
                <template v-if="!loader"> 
                    <b-row class="justify-content-center " v-if="correcto == 0">
                        <b-col cols="12" sm="6" md="6" :style="isMobile ? 'margin-left: -1.2rem;' : ''">       
                          <center>
                            <div class="game" style="color:#fff; ">
                                <canvas id="canvas" width="300px" height="300px"></canvas>
                                <div id="comentarios" class="mt-5" style="font-size:20px; display:none;">
                                    <!-- <p>Comentarios</p> -->
                                </div>
                                <div class="button">
                                    <button id="restablecer" type="button" name="button"></button>
                                </div>
                            </div>   
                            </center>      
                        </b-col>
                    </b-row>
                    <template v-else-if="correcto == 1">
                        <br>
                            <p class="text-white" :style="isMobile ? 'font-size:28px;' : 'font-size:45px;'">
                                <b>¡Felicidades!</b> <br>
                                Cumpliste la misión.
                                <br> ¡Ganaste <span style="color:#187abe;">05 Puntos!</span>
                            </p>
                        <br>
                    </template>
                    <template v-else-if="correcto == 2 ">
                        <br>
                            <p class="text-white" :style="isMobile ? 'font-size:32px;' : 'font-size:45px;'">
                                <b>Gracias por participar.</b>
                                <br> 
                                Prepárate mejor para la próxima misión 
                            </p>
                        <br>
                    </template>
                    <br>
                </template>
                <template v-else>
                    <center><b-spinner style="color:#fff;" small></b-spinner></center> 
                </template>
               </b-card-text>
              </b-card>         
            </div>
         </b-col>
        </b-row>
    </div>
  </div>
</template>
<script>
/*******************************
  Este codigo se baso en el codigo del juego de Gato
  presentado en el libro 'Programe Juegos con HTML5 de
  Francisco Javier Arce Anguiano'
*******************************/
import Vue from "vue";

'use strict'
/************************
    VARIABLES GLOBALES
*************************/
var canvas; var context; //variables del canvas y su contexto
var comentario; //variable del DOM donde se imprimira los comentarios
var restablecer;
var gameover = false; //variables para saber si alguien ya perdio
var tiradas = 0; //tiradas hechas
var fichaArray = new Array(); //array de  las fichas
var FILAS = 3; //numero de las filas
var COLUMNAS = 3; //numero de las filas
var fichas_X = 0;
var fichas_O = 0;
var grosorLinea = 4; //grosor de las lineas separativas de las fichas
var anchoFicha = 0; //ancho de las fichas
var fuenteFicha = "bold 100px Arial";
var colorFichaX = "#fff";
var colorFichaO = "#5895DA";
var tiempoParaPensarMaquina = 400; //tiempo par que piense la maquina expresado en milisegundos
var tiempoParaMostrarBoton = 160; //tiempo para mostrar el boton para volver a jugar
// var VALIDAS = false;
/************************
        CLASSES
*************************/
/***CLASE DE LAS FICHAS***/
class Ficha {
  constructor(f, c) {
    this.f = f;
    this.c = c;
    this.valor = "";
    this.peso = 0;
  }
  pinta(context, valor){
    this.valor = valor;
    //context.fillStyle = "#5895DA";
    //context.fillRect(0,0,100,100);
    context.font = fuenteFicha;
    context.fillStyle = (valor=="x")?colorFichaX:colorFichaO;
    //context.fillText('x', 0, 100, )
    var xt = ((this.c)*anchoFicha)+20;
    var yt = ((this.f+1)*anchoFicha)-25;
    context.fillText(this.valor, xt,yt,anchoFicha,anchoFicha);
  }
}


/************************
        FUNCIONES
*************************/

/***ASIGNAMOS VALORES A LAS VARIABLES DEPENDIENTES DEL CANVAS***/
function ComenzarJuego() {
//   restablecer.style.display = 'none';
  context.clearRect(0, 0, canvas.width, canvas.height);
  tiradas = 0;
  gameover = false;
  anchoFicha = canvas.width / 3;
  context.lineWidth = grosorLinea;
  context.strokeStyle = '#5cb617';
  CrearFichas();
  DibujarTablero();
  canvas.addEventListener('click', SeleccionaFicha, false);
  Comentar('Pulse su Jugada...');
  console.log('paso');
}

// function verStat(val) {
//  VALIDAS = val;
//  console.log(VALIDAS);

// //  guardarJugada();
// //  console.log(correctos);
// //  Vue.set(this.correcto, val, true);
// }

/***DIBUJAMOS EL TABLERO***/
function DibujarTablero(){
  for(let i = 0; i < COLUMNAS; i++){
    //dibujamos linea horizontal -
    context.beginPath();
    context.moveTo(i * anchoFicha, 0);
    context.lineTo(i * anchoFicha, canvas.width);
    context.stroke();
    //dibujamos linea vertical |
    context.beginPath();
    context.moveTo(0, i * anchoFicha);
    context.lineTo(canvas.width, i * anchoFicha);   
    context.stroke();
  }
}

/***ESCRIBIMOS COMENTARIOS***/
function Comentar(texto) {
  comentario.innerHTML = texto;
}

/***CREAMOS FICHAS***/
function CrearFichas() {
  for(let f = 0; f < FILAS; f++){
    fichaArray[f] = new Array(3);
    for(let c = 0; c < COLUMNAS; c++){
      fichaArray[f][c] = new Ficha(f, c);
    }
  }
  console.log(fichaArray);
}

/**AJUSTAMOS POSICION DEL PUNTERO RELATIVAMENTE A UN ELEMENTO**/
function AjustarPosicion(e, elemento){
  var clientRect = elemento.getBoundingClientRect();
  return {
    x: Math.round(e.x - clientRect.left),
    y: Math.round(e.y - clientRect.top)
  }
}

/***SELECCIONAMOS FICHA***/
function SeleccionaFicha(e) {
  var pos = AjustarPosicion(e, canvas);
  var c = Math.trunc(((pos.x>=canvas.width)?canvas.width-1:pos.x) / anchoFicha);
  var f = Math.trunc(((pos.y>=canvas.height)?canvas.height-1:pos.y) / anchoFicha);
  if(fichaArray[f][c].valor == ""){
    console.log('Has Seleccionado la ficha: '+f+', '+c);
    tiradas++;
    canvas.removeEventListener('click', SeleccionaFicha, false);
    fichaArray[f][c].pinta(context, 'x');
    console.log('tiradas: '+tiradas);
    Comentar('Pensando...');
    setTimeout(JugadaMaquina, tiempoParaPensarMaquina);
  }
  else {
    Comentar('Esta ficha ya esta en uso!');
  }
}

/************************
CALCULO PESOS DE LAS FICHAS
*************************/
function CalculoPeso(ficha, x, o) {
  if(ficha.valor == ""){
    if(x == 0 && o == 2){
      ficha.peso += 10;
    } else if (x == 2 && o == 0) {
      ficha.peso += 6;
    } else if (x == 0 && o == 1) {
      ficha.peso += 3;
    } else {
      ficha.peso += 1;
    }
  }
  else {
    ficha.peso = 0;
  }
}

/************************
 VERIFICAR FIN DEL JUEGO
*************************/
function VerificarGameover(x, o) {
  var fin = false;
  if(x == 3) {
    fin = true;
    // Comentar('Has Ganado, da clic en enviar resultados!');
       Vue.prototype.$bus.$emit("guardaDatos")
    setTimeout(GameOver, tiempoParaMostrarBoton);
  }
  if(o == 3){
    fin = true;
    Vue.prototype.$bus.$emit("guardaDatos2")
    // Comentar('Has Perdido, da clic en enviar resultados');
    
    setTimeout(GameOver, tiempoParaMostrarBoton);
  }
  return fin;
}

/************************
FUNCION AL TERMINAR EL JUEGO
*************************/
function GameOver() {
  if(gameover){
    //si hay un ganador
    // Vue.prototype.$bus.$emit("guardaDatos")
  }else {
    //si no hay un ganador
    // Vue.prototype.$bus.$emit("guardaDatos2")
  }
  restablecer.style.display = '';
}

/************************
    JUGADA MAQUINA
*************************/
function JugadaMaquina() {
  tiradas++;
  console.log('tiradas: '+tiradas);
  if(gameover == false){
    //verificamos y calculamos el peso de las fichas
    VerificaFilas(true);
    VerificaColumnas(true);
    VerificaDiagonal_1(true);
    VerificaDiagonal_2(true);
    //seleccionamos la mejor Jugada

    var mejorJugada = 0;
    var x, y;
		for(let f = 0; f < FILAS; f++){
      for(let c = 0; c < COLUMNAS; c++){
        if(fichaArray[f][c].peso > mejorJugada){
          mejorJugada = fichaArray[f][c].peso;
          y = f; x = c;
        }
      }
    }
    //aplicamos Jugada
    if(tiradas < 10)fichaArray[y][x].pinta(context, 'o');
    //verificamos sin calcular el peso de las fichas
    VerificaFilas(false);
    VerificaColumnas(false);
    VerificaDiagonal_1(false);
    VerificaDiagonal_2(false);
    //verificamos si alguien gano
    if(!gameover){
      if(tiradas < 9){
        canvas.addEventListener('click', SeleccionaFicha, false);
        Comentar('Pulse su Jugada...');
      }else {
        Comentar('Empate!');
         
        setTimeout(GameOver, tiempoParaMostrarBoton);
        ComenzarJuego();

      }
    }
  }
}

/************************
    VERIFICAR FILAS,
    COLUMNAS, DIAGONALES
*************************/
//filas
function VerificaFilas(calculaPeso) {
  if(!gameover){
    for(let f = 0; f < FILAS; f++){
      fichas_X = 0; fichas_O = 0;
      for(let c = 0; c < COLUMNAS; c++){
        fichas_X += (fichaArray[f][c].valor=='x')?1:0;
        fichas_O += (fichaArray[f][c].valor=='o')?1:0;
      }
      if(calculaPeso){
        for(let c = 0; c < COLUMNAS; c++){
          CalculoPeso(fichaArray[f][c], fichas_X, fichas_O);
        }
      }
      gameover = VerificarGameover(fichas_X, fichas_O);
      if(gameover) break;
    }
  }
}
//columnas
function VerificaColumnas(calculaPeso) {
  if(!gameover){
    for(let c = 0; c < COLUMNAS; c++){
      fichas_X = 0; fichas_O = 0;
      for(let f = 0; f < FILAS; f++){
        fichas_X += (fichaArray[f][c].valor=='x')?1:0;
        fichas_O += (fichaArray[f][c].valor=='o')?1:0;
      }
      if(calculaPeso){
        for(let f = 0; f < FILAS; f++){
          CalculoPeso(fichaArray[f][c], fichas_X, fichas_O);
        }
      }
      gameover = VerificarGameover(fichas_X, fichas_O);
      if(gameover) break;
    }
  }
}
//diagonal_1_\
function VerificaDiagonal_1(calculaPeso) {
  if(!gameover){
    fichas_X = 0; fichas_O = 0;
    for(let i = 0; i < FILAS; i++){
      fichas_X += (fichaArray[i][i].valor=='x')?1:0;
      fichas_O += (fichaArray[i][i].valor=='o')?1:0;
    }
    if(calculaPeso){
      for(let i = 0; i < FILAS; i++){
        CalculoPeso(fichaArray[i][i], fichas_X, fichas_O);
      }
    }
    gameover = VerificarGameover(fichas_X, fichas_O);
  }
}
//diagonal_2_/
function VerificaDiagonal_2(calculaPeso) {
  if(!gameover){
    fichas_X = 0; fichas_O = 0;
    var j = 2;
    for(let i = 0; i < FILAS; i++){
      fichas_X += (fichaArray[j][i].valor=='x')?1:0;
      fichas_O += (fichaArray[j][i].valor=='o')?1:0;
      j--;
    }
    if(calculaPeso){
      j = 2;
      for(let i = 0; i < FILAS; i++){
        CalculoPeso(fichaArray[j][i], fichas_X, fichas_O);
        j--;
      }
    }
    gameover = VerificarGameover(fichas_X, fichas_O);
  }
}



// import Vue from "vue";
import * as moment from 'moment';
export default {
  name: 'juego3',
  data () {
    return {
        letters: {},
        hiddenWord: [],
        remainingAttempts: 0,
        countdown: moment.utc(61).format('HH:mm:ss'),
        expires_in: null,
        interval: null,
        Clickinterval: false,
        loader:false,
        isMobile: this.$mobile(),
        correcto:0,
        valid:false
    }
  },
  created() {
    this.$bus.$on("guardaDatos",
      () => {
        this.valid = true;
        // this.guardarJugada();
        this.correcto = 1;
      });

    this.$bus.$on("guardaDatos2",
      () => {
         this.valid = false;
        //  this.guardarJugada();
        this.correcto = 2;
      });
  },
  mounted(){
    // this.getJuego();
    setTimeout(() => {
        // this.correcto = 0;
        canvas = document.querySelector('#canvas');
        comentario = document.querySelector('#comentarios');
            restablecer = document.querySelector('#restablecer');
            if (restablecer)
            {
                restablecer.addEventListener('click', ComenzarJuego, false); 
            }

            console.log(canvas);

            if(canvas && canvas.getContext && comentario && restablecer){
            context = canvas.getContext('2d');
            if(context){
                console.log('contexto creado correctamente!');
                ComenzarJuego();
            }
            else {
                alert('error al crear tu contexto');
            }
            }
    }, 500);
        
  },
  destroyed () {
      clearInterval(this.interval);
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
  },
  methods: {
       getJuego(){
         this.loader = true;
         var url= 'juego/estatus2?email='+ this.user.email;
            this.$api.get(url).then(
                ({data}) => {
                    console.log(data);
                    this.loader = false;
                    this.correcto = data.correcto;
                    if (data.correcto != 0)
                    {
                        clearInterval(this.interval);    
                    }
                    if(data.correcto == 1){
                    this.$confetti.start({
                        particles: [
                            {
                            type: 'rect',
                            },
                            {
                            type: 'circle',
                            },
                        ],
                        defaultColors: [
                            '#5cb617',
                            '#ffffff',
                            '#187abe'
                        ],
                    });
                    setTimeout(() => {
                        this.$confetti.stop();
                    }, 2000);
                    }
                    if (data.correcto == 0)
                    {
                        this.countdown = moment.utc(32).format('HH:mm:ss');
                        this.expires_in = 1;
                        this._setInterval();
                    }
                }
            );
        },
        _setInterval: function() {
            // this.mostrarPregunta = 1;
            if(this.Clickinterval==false){
                this.interval = setInterval(() => {
                    if (this.expires_in === 0) {
                        clearInterval(this.interval);
                    } else {
                        this.expires_in += 1;
                        if(this.expires_in>0){
                            this.countdown = moment.utc(this.expires_in * 1000).subtract(1, 'seconds').format('HH:mm:ss');
                        }
                    }
                }, 1000);
                this.Clickinterval = true;
            }
        },
        guardarJugada(){
         this.loader = true;
        //  console.log(VALIDAS);
         this.$api.post('juego/store', {
                mrt: this.user.mrt,
                idpdv: this.user.id_pdv,
                email: this.user.email,
                seccion: 'Juego',
                puntos: this.valid == true ? 5 : 0 ,
                perfil: this.user.perfil,
                respuesta: 'Gato',
                tiempo: this.countdown,
                correcto: this.valid == true ? 1 : 0 ,
            }).then(response => {
                    console.log(response);
                    this.loader = false;  
                    clearInterval(this.interval);
                    this.getJuego();
                })
                .catch(error => {
                    console.log(error);
                    this.loader = false;
                });
          
        }
    }
}


/************************
    EJECUTAR JUEGO
*************************/



</script>
<style>
 #fondoPista{
     background-image: url('https://www.inquebrantables2022.com.mx/elementos/ahorcado.jpeg');
     width: 100%!important;
     
    }
    body{
        text-align: center;
    }
    #canvas{
        background-color: #002A51;
        border: 3px solid #5cb617;
    }

    @media only screen and (min-width: 1000px) 
{
        #canvas
        {
            margin-left: -40px;
        }
}

@media only screen and (min-width: 500px) 
{
      #canvas
        {
            margin-left: -70px;
        }
}

@media only screen and (min-width: 400px) 
{
      #canvas
        {
            margin-left: -19px;
        }
}

@media only screen and (min-width: 1300px) 
{
      #canvas
        {
            margin-left: 10px;
        }
}
</style>